import React from 'react'

function Navbar() {
	return (
		<div className='navbar_container'>
			<div className='link'>Home</div>
			<div className='link'>About</div>
			<div className='link'>Products</div>
			<div className='link'>Contact Us</div>
		</div>
	)
}

export default Navbar