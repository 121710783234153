import './App.css';
import Navbar from './components/navbar/navbar';

function App() {
	return (
		<section className="container">
			<div className='heading'>Kothiwal Urja Enterprises</div>
			<Navbar />
		</section>
	);
}

export default App;
